import React from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import style from "./index.module.css"
import AndvicHeader from "../../../components/andvic-header"
import BurgerMenu from "../../../components/burger-menu"
import SEO from "../../../components/seo"

const BackgroundSection = ({ className }: { className: any }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "kitchen-about.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
          >
            <AndvicHeader></AndvicHeader>
          </BackgroundImage>
        </>
      )
    }}
  />
)

export default function IndexPage(): JSX.Element {
  return (
    <Layout>
      <SEO title="Residential Services" />
      <BurgerMenu></BurgerMenu>

      <BackgroundSection className={style.header}></BackgroundSection>
      <div className={style.mainHeader}>
        <div>
          <h1 className={style.header1}>Residential</h1>
        </div>
      </div>
      <div className={style.copy}>
        <div>
          <p>
            Andvic Projects are an experienced Sydney residential builder
            transforming architectural concepts into the builtform and
            delivering quality outcomes for homeowners and architects.
          </p>
          <p>
            We understand the construction of a home is a very personal
            experience and a high desire for the architectural vision to be
            realised. Our appreciation of aesthetics and spatial efficiency
            combined with our experience in constructability, maintenance
            requirements and structural knowledge allows us to provide
            innovative design refinements and enhanced building longevity to the
            final product.
          </p>
          <p>
            The depth of capabilities and resources within our team and trusted
            professional subcontractors allows us to specialise in the delivery
            of the following types of residential projects:
          </p>
          <ul>
            <li>New Luxury Architectural homes</li>
            <li>
              Renovations / alterations and additions to existing structures
            </li>
            <li>Restricted site access and staging requirements</li>
            <li>Duplexes</li>
          </ul>
          <p>
            Our success has been attributed to the following qualities advocated
            by our management and project teams
          </p>
          <ul>
            <li>Quality workmanship</li>
            <li>Attention to detail</li>
            <li>Collaborative nature</li>
            <li>Clear communication</li>
            <li>Being client focused</li>
          </ul>
          <p>
            We have in-house capability and experience to provide the following
            services
          </p>
          <ul>
            <li>Concept design feasibility assessment</li>
            <li>
              Early Contractor Involvement (ECI) and cost planning value
              management
            </li>
            <li>Lump Sum construction contracts</li>
            <li>Cost Plus construction contracts</li>
            <li>Design and Construct contracts</li>
            <li>Project management services from conceptual to delivery</li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
